import React from "react"
import GoBackArrow from "../../components/GoBackArrow"

export default function main() {
  return (
    <div className="whatwedo-page">
      <GoBackArrow />
      <div className="secondary-pink-block"></div>
      <div className="page-wrapper">
        <h1 className="description-header">WHAT WE DO</h1>
        <p className="description">
          At Smiley Sound we specialise in live sound engineering, av
          installation and studio setups, conferences, music production, mixing
          and mastering & more ...{" "}
        </p>
        <h2 className="block-separator">OUR PARTNERS AND CLIENTS:</h2>
        <div className="clients-list">
          <p>
            <a href="https://www.acehotel.com/london/" target="_blank">
              Ace Hotel
            </a>
            &nbsp;//
          </p>
          <p>
            <a href="https://www.londonmet.ac.uk/" target="_blank">
              <span className="empty-space-1st">&nbsp;</span>London MET Uni
            </a>
            &nbsp;//
          </p>
          <p>
            <a href="https://www.ladbible.com/uk-news" target="_blank">
              <span className="empty-space-2nd">&nbsp;</span>Lad Bible
            </a>
            &nbsp;//
          </p>
          <p>
            <a href="https://www.clashmusic.com/" target="_blank">
              <span className="empty-space-3rd">&nbsp;</span>Clash Mag
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
