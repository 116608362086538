import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const GoBackArrow = props => {
  return (
    <div className="arrow-page-left">
      <p>{props.text}</p>
      <Link to="/">
        <FontAwesomeIcon icon={faArrowLeft} size="2x" />{" "}
      </Link>
    </div>
  )
}
export default GoBackArrow
